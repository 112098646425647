import React from "react";
import { NavLink } from "react-router-dom";
import Banner from "../../assets/images/AboutTrade.jpg"
import { useTranslation } from "react-i18next";
function FBanner() {
  const { t, i18n } = useTranslation();
  return (
    <div className="">
      <div class="relative font-sans before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10">
        <img
          src={Banner}
          alt="Banner Image"
          class="absolute inset-0 w-full h-full object-cover"
        />

        <div class="min-h-[350px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6">
          <div data-aos="zoom-in" data-aos-duration="2000">
            <h2 class="sm:text-4xl text-2xl font-bold mb-6">
            {t('Bole Sub-City Administration Trade Office')}
            </h2>
          </div>
          <div data-aos="zoom-ing" data-aos-duration="3000">
            <p class="sm:text-lg text-base text-center text-gray-200">
              {t('Contact us for more information')}
            </p>
          </div>

          <button
            
            type="button"
            class="mt-12 bg-transparent text-white text-base py-3 px-6 border border-white rounded-lg hover:bg-white hover:text-black transition duration-300"
          >
            <a href="/contact">
            {t('Contact Now')}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default FBanner;
