import React from 'react'
import History from '../Components/About-us/History'
import { useTranslation } from 'react-i18next';
import FAQ from '../Components/FAQ';
import FBanner from '../Components/About-us/FBanner';
function About() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <History/>
      <FAQ/>
      <FBanner/>
    </div>
  )
}

export default About