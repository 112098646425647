import React from 'react';
import { Carousel } from 'antd';
import { FaUser } from 'react-icons/fa'; // Importing user icon from react-icons
// import 'antd/dist/antd.css'; // Import antd styles

const testimonials = [
    {
      name: "Alemayehu Teshome",
      text: "Friendship Hypermarket always provides excellent service and a wide range of products. Highly recommended!",
      date: "2024-06-10",
      role: "Regular Customer",
    },
    {
      name: "Marta Abebe",
      text: "I love shopping at Friendship Hypermarket! Their products are top quality, and the staff is very friendly.",
      date: "2024-05-22",
      role: "Frequent Shopper",
    },
    {
      name: "Samuel Yared",
      text: "Great place for all your shopping needs. The prices are reasonable and the location is very convenient.",
      date: "2024-04-15",
      role: "Satisfied Customer",
    },
    {
      name: "Hana Girma",
      text: "The best shopping experience! They have everything you need, and the staff is super helpful.",
      date: "2024-07-01",
      role: "Happy Customer",
    },
    {
      name: "Bekele Getachew",
      text: "Friendship Hypermarket has the best selection of products. I always find what I need.",
      date: "2024-03-11",
      role: "Loyal Shopper",
    },
    {
      name: "Tigist Haile",
      text: "The quality of products at Friendship Hypermarket is unmatched. The staff is very courteous.",
      date: "2024-02-14",
      role: "Frequent Shopper",
    },
    {
      name: "Abel Desta",
      text: "A wonderful place to shop with competitive prices and friendly staff.",
      date: "2024-01-23",
      role: "Regular Customer",
    },
    {
      name: "Mulu Nega",
      text: "I enjoy shopping here because of the variety and the excellent customer service.",
      date: "2023-12-05",
      role: "Satisfied Customer",
    },
    {
      name: "Fikadu Solomon",
      text: "Friendship Hypermarket is my go-to place for all my shopping needs. They never disappoint.",
      date: "2023-11-20",
      role: "Happy Customer",
    },
    {
      name: "Selamawit Tsegaye",
      text: "I always have a pleasant shopping experience at Friendship Hypermarket. The staff is very helpful.",
      date: "2023-10-17",
      role: "Regular Shopper",
    },
    {
      name: "Tesfaye Mulugeta",
      text: "Great service and a wide variety of products. I highly recommend Friendship Hypermarket.",
      date: "2023-09-28",
      role: "Satisfied Customer",
    },
    {
      name: "Liya Melaku",
      text: "I love the friendly atmosphere and the quality of products at Friendship Hypermarket.",
      date: "2023-08-19",
      role: "Frequent Shopper",
    },
    {
      name: "Yared Alemu",
      text: "The best shopping experience in town. The prices are fair and the products are top-notch.",
      date: "2023-07-25",
      role: "Happy Customer",
    },
    {
      name: "Helen Tesfaye",
      text: "I always find what I need at Friendship Hypermarket. The staff is very welcoming.",
      date: "2023-06-30",
      role: "Loyal Customer",
    },
    {
      name: "Getu Tefera",
      text: "Excellent place to shop with a wide range of products and great customer service.",
      date: "2023-05-18",
      role: "Satisfied Shopper",
    },
    {
      name: "Meseret Fikre",
      text: "Friendship Hypermarket is my favorite place to shop. They always have the best products.",
      date: "2023-04-10",
      role: "Regular Shopper",
    },
    {
      name: "Girma Bekele",
      text: "A great place to find everything you need. The prices are very reasonable.",
      date: "2023-03-15",
      role: "Happy Shopper",
    },
    {
      name: "Rahel Yohannes",
      text: "I love the convenience and the quality of service at Friendship Hypermarket.",
      date: "2023-02-07",
      role: "Loyal Customer",
    },
    {
      name: "Kebede Hailu",
      text: "An excellent shopping experience every time. The staff is always ready to help.",
      date: "2023-01-26",
      role: "Frequent Shopper",
    },
    {
      name: "Abeba Alemayehu",
      text: "Friendship Hypermarket has the best customer service and the best selection of products.",
      date: "2022-12-19",
      role: "Happy Customer",
    },
  ];

const CustomerTestimonials = () => {
  return (
    <section className="bg-white dark:bg-gray-900 py-12">
      <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
        <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-8">Customer Testimonials</h2>
        <Carousel
          autoplay
          dots={false}
          slidesToShow={3}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="px-4">
              <figure className="max-w-xs mx-auto bg-white p-6 rounded-lg shadow-md dark:bg-gray-800">
                <svg
                  className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <blockquote>
                  <p className="text-2xl font-medium text-gray-900 dark:text-white">{testimonial.text}</p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                  <FaUser className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                    <div className="pr-3 font-medium text-gray-900 dark:text-white">{testimonial.name}</div>
                    <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">{testimonial.role}</div>
                  </div>
                </figcaption>
                {/* <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">{testimonial.date}</p> */}
              </figure>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default CustomerTestimonials;
