import React from "react";
import { useTranslation } from "react-i18next";
function EnjoyAnnouncements() {
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-gradient-to-b from-blue-900 via-blue-700 to-blue-500 px-6 sm:py-20 py-10 font-sans">
      <div className="max-w-screen-xl mx-auto text-center text-white">
        <div data-aos="fade-down">
          <h1 className="text-4xl sm:text-5xl font-extrabold leading-tight mb-4">
          {t('Enjoy Our Announcements')}
        </h1>
        </div>
        <div data-aos="zoom-out">
        <p className="text-lg mb-8">
          {t('Stay tuned for the latest updates and news from our office.')}
        </p>
        </div>
        
        <button
          type="button"
          className="bg-blue-600 hover:bg-blue-700 text-white text-lg font-semibold px-8 py-3 rounded-full transition duration-300 ease-in-out shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {t('Explore Announcements')}
        </button>
      </div>
      <div className="w-full p-4">
        <div class="  animate-gradient-x text-white rounded-lg shadow-lg p-8 max-w-xl mx-auto font-[sans-serif] w-full">
          <marquee
            class="mt-4 text-base font-italic"
            behavior="scroll"
            direction="left"
            scrollamount="3"
          >
            {t('Marqeuee')}
          </marquee>
        </div>
      </div>
    </div>
  );
}

export default EnjoyAnnouncements;
