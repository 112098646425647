import React, { useState, useEffect } from "react";
import axios from "axios";

function MessagesList() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get("http://localhost:3000/api/contmessages"); // Adjust URL as per your backend setup
      setMessages(response.data[0].text);
      console.log("Response Data ", response.data[0].text)
      // const data=response.data[0].text
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };



  return (
    <div className="overflow-hidden bg-gray-200">
      <marquee className="text-xl font-bold text-blue-800" direction="left" scrollamount="10">
        {messages}
      </marquee>
    </div>
  );
}

export default MessagesList;
