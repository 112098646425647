import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function FAQ() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <div>
      <section className="bg-[#1D1D1D] text-gray-100 py-32 min-h-screen">
        <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
          <h2 className="mb-12 text-4xl font-bold leading text-center sm:text-5xl" data-aos="fade-up">
            {t('Frequently Asked Questions')}
          </h2>
          <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                What services does the Bole Sub-City Trade Office provide?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  We provide support for business development, regulatory compliance, market supervision, and consumer protection.
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                How can I apply for a business license from the Trade Office?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  You can apply for a business license through our office by submitting the required documents and completing the application process.
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                What is the role of the Trade Office in monitoring market activities?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  We monitor market activities to ensure compliance with regulations, maintain product quality, and facilitate fair competition.
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                How does the Trade Office support local investors?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  We support local investors by offering technical assistance, advisory services, and creating favorable conditions for business expansion.
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                What measures does the Trade Office take against illegal trade practices?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  We collaborate with relevant authorities to prevent and control illegal trade, ensuring a lawful and structured trading environment.
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                How does the Trade Office ensure consumer protection?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  We monitor consumer product quality, control market supply, and enforce fair trading practices to protect consumer rights.
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                Does the Trade Office manage the registration of trade associations?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  Yes, we support the establishment of trade associations within Bole Sub-City and manage the city's trade register.
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                What are the benefits of integrating informal businesses into the formal economy?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  Integrating informal businesses promotes economic growth, expands the formal sector, and enhances regulatory oversight.
                </p>
              </div>
            </details>
            <details data-aos="fade-right">
              <summary className="py-2 outline-none cursor-pointer">
                How does the Trade Office contribute to economic stability in Bole Sub-City?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  We promote economic stability by fostering business growth, maintaining market integrity, and supporting sustainable development.
                </p>
              </div>
            </details>
            <details data-aos="fade-left">
              <summary className="py-2 outline-none cursor-pointer">
                How can I report a market-related issue to the Trade Office?
              </summary>
              <div className="px-4 pb-4">
                <p>
                  You can report market-related issues by contacting our office directly or using our designated reporting channels to ensure prompt action.
                </p>
              </div>
            </details>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;
