import React, { useEffect } from "react";
import { Button } from "antd";
import bannerImage from "../assets/Friendship/11.jpg"; // Import the image
import "aos/dist/aos.css";
import aos from "aos";
import { useTranslation } from "react-i18next";

const FriendshipGroupInfo = () => {
  useEffect(() => {
    aos.init({ duration: 2000 }); // Initialize AOS with options if needed
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <div
      className="relative bg-cover bg-center h-screen flex items-center justify-center py-0"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div
        className="z-10 text-center text-white p-10 sm:p-20"
        data-aos="fade-up"
      >
        <h1 className="text-4xl sm:text-5xl font-bold mb-6 text-yellow-500">
          {t("Friendhip Hypermarket")}
        </h1>
        <p
          className="text-lg sm:text-xl lg:text-2xl mb-8 text-blue-200"
          data-aos="fade-up"
        >
          {t(
            "Friendship Hypermarket, operating as a Hypermarket, is dedicated to enriching our community through the provision of exceptional products and services. With an unwavering commitment to excellence and customer satisfaction, we aim to create a significant and positive impact in the lives of our valued customers and trusted partners. Our mission extends beyond commerce; we strive to foster lasting relationships and contribute meaningfully to the local economy, enhancing the quality of life for everyone we serve."
          )}
        </p>
        <div className="flex justify-center space-x-4" data-aos="fade-up">
          <Button type="primary" size="large">
           {t('Explore Services')}
          </Button>
          <Button size="large">{t('Contact Us')}</Button>
        </div>
      </div>
    </div>
  );
};

export default FriendshipGroupInfo;
