import React from "react";
import Contactus from "../Components/Contact-us/Contactus"
import Location from "../Components/Contact-us/Location";
import { useTranslation } from 'react-i18next';
import ContactUs from "../Components/Contact";
import OperatingHours from "../Components/OperatingHours";
import Locationtwo from "../Components/Services/Locations"

function Contact() {
  const { t, i18n } = useTranslation();
  return (
    <div >
     <ContactUs/>
     <Locationtwo/>
     <OperatingHours/>
     <Location/>
    </div>
  );
}

export default Contact;
