import React from "react";
import { FaHome, FaComments, FaNewspaper, FaBullhorn, FaImages, FaUserTie } from "react-icons/fa";

const AdminWelcomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 flex items-center justify-center">
      <div className="max-w-4xl mx-auto px-8 py-12 bg-white shadow-2xl rounded-2xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-8 text-center">
          Welcome to Court Website Administration
        </h1>
        <div className="text-lg text-gray-800 mb-8">
          <p className="text-center mb-6">
            Manage your court's online presence efficiently with our intuitive admin panel. Here’s what you can do:
          </p>
          <ul className="list-disc ml-6 mt-4 space-y-4">
            <li className="flex items-start space-x-2">
              <FaHome className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Home Page:</strong> Manage homepage content for visitors.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaComments className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>User Feedback:</strong> View and respond to visitor feedback.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaNewspaper className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add News:</strong> Keep visitors informed with news updates.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaBullhorn className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Director Message:</strong> Highlight announcements from the court's director.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaImages className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Image Gallery:</strong> Showcase events and activities through images.
              </span>
            </li>
            <li className="flex items-start space-x-2">
              <FaUserTie className="text-blue-500 mt-1" />
              <span className="ml-2">
                <strong>Add Officials:</strong> Maintain an updated directory of court officials and leadership.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminWelcomePage;
