import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const categories = [
  'Welcome Page', 'House Hold', 'Cosmetic', 'Food Stuffs', 'Stationary', 
  'Garment and Shoe', 'Electronics', 'Office Furniture', 'Beverage', 
  'Mineral Water', 'Alcohol', 'Soft Drink', 'Milk and Milk Product', 
  'Meat and Meat Product', 'Vegetables Fruit', 'Toys and Games', 'Pet Supplies'
];

const ProductManagement = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [filterCategory, setFilterCategory] = useState('');

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    handleFilterCategoryChange(filterCategory);
  }, [products, filterCategory]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/product');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleFilterCategoryChange = (category) => {
    setFilterCategory(category);
    if (category === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => product.category === category);
      setFilteredProducts(filtered);
    }
  };

  const handleSubmit = async (values) => {
    if (!values.name || !values.price || !values.category || !values.image) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('price', parseFloat(values.price));
    formData.append('category', values.category);
    formData.append('description', values.description);
    formData.append('image', values.image[0].originFileObj);

    try {
      await axios.post('http://localhost:3000/api/product', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Product added successfully');
      fetchProducts();
      form.resetFields();
    } catch (error) {
      console.error('Error adding product:', error);
      message.error('Failed to add product');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`http://localhost:3000/api/product/${id}`);
        message.success('Product deleted successfully');
        fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
        message.error('Failed to delete product');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Product',
      width: 800,
      content: (
        <div>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Price:</strong> {record.price}</p>
          <p><strong>Category:</strong> {record.category}</p>
          <p><strong>Description:</strong> {record.description}</p>
          <p><strong>Image:</strong> <img src={`http://localhost:3000/${record.image}`} alt={record.name} style={{ maxWidth: '100%', height: 'auto' }} /></p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentProduct(record);
    updateForm.setFieldsValue({
      name: record.name,
      price: record.price,
      category: record.category,
      description: record.description,
      image: null,
    });
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('price', parseFloat(values.price));
    formData.append('category', values.category);
    formData.append('description', values.description);
    if (values.image && values.image.length > 0) {
      formData.append('image', values.image[0].originFileObj);
    }

    try {
      await axios.put(`http://localhost:3000/api/product/${currentProduct._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Product updated successfully');
      fetchProducts();
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating product:', error);
      message.error('Failed to update product');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      ellipsis: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      ellipsis: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      render: (text, record) => (
        <>
          <Button onClick={() => handleView(record)} style={{ marginRight: 8 }}>View</Button>
          <Button onClick={() => handleUpdate(record)} type="primary" style={{ marginRight: 8 }}>Update</Button>
          <Button onClick={() => handleDelete(record._id)} type="danger">Delete</Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Form layout="inline">
        <Form.Item label="Category">
          <Select
            value={filterCategory}
            onChange={handleFilterCategoryChange}
            style={{ width: 200 }}
          >
            <Option value="">All</Option>
            {categories.map((category) => (
              <Option key={category} value={category}>{category}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={filteredProducts}
        rowKey="_id"
        pagination={{
          current: currentPage,
          pageSize,
          total: filteredProducts.length,
          onChange: (page) => setCurrentPage(page),
        }}
      />
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Price" name="price">
          <Input />
        </Form.Item>
        <Form.Item label="Category" name="category">
          <Select>
            {categories.map((category) => (
              <Option key={category} value={category}>{category}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Upload beforeUpload={() => false} listType="picture">
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Product</Button>
        </Form.Item>
      </Form>
      <Modal
        title="Update Product"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        onOk={updateForm.submit}
      >
        <Form form={updateForm} onFinish={handleUpdateSubmit} layout="vertical">
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Price" name="price">
            <Input />
          </Form.Item>
          <Form.Item label="Category" name="category">
            <Select>
              {categories.map((category) => (
                <Option key={category} value={category}>{category}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Image" name="image">
            <Upload beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Select Image</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductManagement;
