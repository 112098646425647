import React from 'react';

const AdminDashboard = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Court Website Administration</h1>
      
      <div className="bg-white shadow-md rounded-lg overflow-hidden mb-4">
        <div className="p-4 bg-gray-200 border-b border-gray-300">
          <h2 className="text-xl font-bold">Main Functionalities</h2>
        </div>

        <div className="p-4">
          <div className="mb-4">
            <h3 className="text-lg font-bold mb-2">1. Home Page</h3>
            <p className="text-gray-700">
              This tab redirects to the home page of the site where you can manage the content visible to visitors.
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-bold mb-2">2. User Feedback</h3>
            <p className="text-gray-700">
              View and respond to feedback submitted via the website's contact page, ensuring transparent communication.
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-bold mb-2">3. Add News</h3>
            <p className="text-gray-700">
              Add and manage news articles and updates to keep visitors informed about recent developments.
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-bold mb-2">4. Add Director Message</h3>
            <p className="text-gray-700">
              Display messages from the court's director on the homepage to communicate important announcements.
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-bold mb-2">5. Add Image Gallery</h3>
            <p className="text-gray-700">
              Upload images to the site's Gallery page to visually showcase events and activities.
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-bold mb-2">6. Add Officials</h3>
            <p className="text-gray-700">
              Manage information about officials and leadership in the court to maintain an updated directory.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
