import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { TagOutlined, PercentageOutlined, GiftOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
const OffersSection = () => {
  
 const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
 
  const offers = [
    {
      icon: <TagOutlined className="text-4xl text-green-500" />,
      title: t('Discounts on Groceries'),
      description: t('Enjoy up to 20% off on selected grocery items. Limited time offer!'),
    },
    {
      icon: <PercentageOutlined className="text-4xl text-green-500" />,
      title: t('Seasonal Sales'),
      description: t('Grab amazing deals during our seasonal sales events. Do nott miss out!'),
    },
    {
      icon: <GiftOutlined className="text-4xl text-green-500" />,
      title: t('Exclusive Member Offers'),
      description: t('Sign up for our membership to receive exclusive offers and discounts.'),
    },
  ];

  return (
    <div className="bg-slate-800 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-12" data-aos="fade-up">
          {t('Current Offers and Promotions')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {offers.map((offer, index) => (
            <div
              key={index}
              className="bg-gray-100 p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition-shadow duration-300"
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <div className="mb-4">
                {offer.icon}
              </div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">{offer.title}</h3>
              <p className="text-gray-600">{offer.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OffersSection;
