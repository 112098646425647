import React from 'react';
import { useTranslation } from "react-i18next";

function Location() {
  const { t, i18n } = useTranslation();
  return (
    <div>
    <div className="w-full">
        <div className='text-4xl lg:text-4xl font-extrabold text-center text-blue-800'>
            {/* <h1>{t('Location')}</h1> */}
            <h1>{t('Friendship Hypermakrt Bole Branch')}</h1>
        </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.808868644861!2d38.7835903749086!3d8.98972809107005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b84fb99570403%3A0x8a21e3ebfc8b4fc5!2sFriendship%20Business%20Center!5e0!3m2!1sen!2set!4v1721207165989!5m2!1sen!2set"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Supreme Court of Oromia"
        className="w-full h-96 sm:h-[450px]"
      ></iframe>
    </div>
    <div>
    <div className="w-full">
        <div className='text-4xl lg:text-4xl font-extrabold text-center text-blue-800'>
            {/* <h1>{t('Location')}</h1> */}
            <h1>{t('Friendship Hypermakrt CMC Branch')}</h1>
        </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.4633253571506!2d38.82628597839687!3d9.021429067935818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b9b7a7aa66507%3A0xa2fecffe0285be5e!2sFriendship%20Hypermarket%20-%20CMC%20Branch!5e0!3m2!1sen!2set!4v1721207284073!5m2!1sen!2set"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Supreme Court of Oromia"
        className="w-full h-96 sm:h-[450px]"
      ></iframe>
    </div>
    </div>
    </div>
  );
}

export default Location;
