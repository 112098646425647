import React, { useEffect } from "react";
import Logo from "../../assets/images/Trade Logo.png";
import Mission from "../../assets/images/Mission2.jpg";
import Vission from "../../assets/images/vision.jpg";
import Values from "../../assets/images/Values.jpg";
import Banner from "../../assets/images/Baner44.png";
import AOS from "aos";
import "aos/dist/aos.css";
import HammerBanner from "../../assets/images/tradeb.jpg";
import { useTranslation } from "react-i18next";
function History() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
      duration:2000,
    });
  }, []);
  const sections = [
    {
      title: t('About Us'),
      content:
        t('About Us Description'),
      img: Logo,
    },
    {
      title: t('Mission'),
      content:
        t('Mission Description'),
      img: Mission,
    },
    {
      title: t('Vision'),
      content:
        t('Vision Description'),
      img: Vission,
    },
    {
      title: t('Values'),
      content:
        (
          <div>
  <ul class="list-disc list-inside">
    <li>{t('Values 1')}</li>
    <li>{t('Values 2')}</li>
    <li>{t('Values 3')}</li>
    <li>{t('Values 4')}</li>
    <li>{t('Values 5')}</li>
    <li>{t('Values 6')}</li>
    <li>{t('Values 7')}</li>
  </ul>
</div>
        ),
      img: Values,
    },
  ];

  return (
    <div>
      <div className="relative shadow-2xl mb-8">
        <img
          src={HammerBanner}
          className="w-full h-40 object-cover"
          alt="Banner"
        />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl font-bold">
         {t('about us')}
        </h1>
      </div>
      <div className="">
        <img src={Banner} className="w-full h-32" />
      </div>
      <div className="container mx-auto pl-20 pb-20 pr-20 pt-5">
        {sections.map((section, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row justify-center items-center gap-4 my-4"
          >
            <div
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              className="md:w-1/2 p-4"
            >
              <h1 className="text-3xl font-bold p-4">{section.title}</h1>
              <p className="text-xl">{section.content}</p>
            </div>
            <div
              data-aos="fade-left"
              data-aos-easing="ease-in-sine"
              className="md:w-1/2 p-4 text-center"
            >
              <img
                src={section.img}
                alt={section.title}
                className="w-1/2 mx-auto"
              />
            </div>
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default History;
