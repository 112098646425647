import React, { useState } from 'react';
import axios from 'axios';
import ProductManagement from './ProductManagement';

const categories = [
  'Welcome Page', 'House Hold', 'Cosmetic', 'Food Stuffs', 'Stationary', 
  'Garment and Shoe', 'Electronics', 'Office Furniture', 'Beverage', 
  'Mineral Water', 'Alcohol', 'Soft Drink', 'Milk and Milk Product', 
  'Meat and Meat Product', 'Vegetables Fruit', 'Toys and Games'
];

const ProductForm = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('image', image);
      formData.append('price', parseFloat(price));
      formData.append('category', category);
      formData.append('description', description);

      const response = await axios.post('http://localhost:3000/api/product', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Product added:', response.data);

      // Reset form fields after successful submission (optional)
      setName('');
      setImage(null);
      setPrice('');
      setCategory('');
      setDescription('');

      // To reset the file input field, you may need to create a ref and manually reset it
      document.getElementById('image').value = '';
    } catch (error) {
      console.error('Error adding product:', error);
      // Handle error state or alert the user
      alert('Failed to add product. Please try again.');
    }
  };

  return (
    <div className="">
      <ProductManagement/>
    </div>
  );
};

export default ProductForm;
