import { Outlet } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';
import ContinuedText from "../Components/ContinuedText";

const MainLayout = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="">
      
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayout;