import React,{useEffect} from "react";
import BannerImage from "../assets/Friendship/Bannertwo.jpg"; // Renamed the image import
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

function Banner() {
  useEffect(() => {
    AOS.init({
      easing: 'ease-in-out',
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <div className="relative">
      <img
        src={BannerImage}
        className="w-full h-[40vh] object-fit blur-sm"
        alt="banner"
      />
      <div className="absolute inset-0  flex items-center justify-center">
        <div data-aos="zoom-in" data-aos-duration="2000">
          <h1 className="text-gray-800 text-xl text-center md:text-6xl font-bold z-10">
          {t('Friendhip Hypermarket')}
          
          </h1>
          <h1 className="text-gray-800 text-xl text-center md:text-5xl font-bold z-10">{t('Delivering Quality, Every Step of the Way')}</h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
