// src/ComplaintsList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal } from 'antd';
// import 'antd/dist/antd.css';

const ComplaintsList = () => {
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState(null);

  useEffect(() => {
    fetchComplaints();
  }, []);

  const fetchComplaints = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/complaints');
      setComplaints(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching complaints', error);
      setLoading(false);
    }
  };

  const deleteComplaint = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/api/complaints/${id}`);
      fetchComplaints();
    } catch (error) {
      console.error('Error deleting complaint', error);
    }
  };

  const viewComplaint = (complaint) => {
    setSelectedComplaint(complaint);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedComplaint(null);
  };

  const columns = [
    { title: 'Full Name', dataIndex: 'fullName', key: 'fullName' },
    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
    { title: 'Sub City', dataIndex: 'subCity', key: 'subCity' },
    { title: 'Woreda', dataIndex: 'woreda', key: 'woreda' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button onClick={() => viewComplaint(record)} type="default" className='text-white bg-blue-700' style={{ marginRight: 8 }}>View</Button>
          <Button onClick={() => deleteComplaint(record._id)} type="default" className='text-white bg-red-500'>Delete</Button>
        </span>
      ),
    },
  ];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-4">Complaints List</h1>
      <Table
        columns={columns}
        dataSource={complaints}
        loading={loading}
        rowKey="_id"
      />
      <Modal
        title="Complaint Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {selectedComplaint && (
          <div>
            <p><strong>Full Name:</strong> {selectedComplaint.fullName}</p>
            <p><strong>Phone:</strong> {selectedComplaint.phone}</p>
            <p><strong>Sub City:</strong> {selectedComplaint.subCity}</p>
            <p><strong>Woreda:</strong> {selectedComplaint.woreda}</p>
            <p><strong>Place:</strong> {selectedComplaint.complaintPlace}</p>
            <p><strong>Details:</strong> {selectedComplaint.complaintDetails}</p>
            {selectedComplaint.evidence && (
              <p><strong>Evidence:</strong> {selectedComplaint.evidence}</p>
            )}
            <p><strong>Desired Resolution:</strong> {selectedComplaint.complaintWant}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ComplaintsList;
