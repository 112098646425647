import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { EnvironmentOutlined, PhoneOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const StoreLocationsSection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const locations = [
    {
      name: t('Main Branch - Bole'),
      address: t('Friendship Mall, Bole, Addis Ababa'),
      phone: '+251 995 880 818',
      hours: '7:30 AM - 4:00 PM',
    },
    {
      name: t('Branch - CMC'),
      address: t('Near Sailite Mihiret Church, CMC, Addis Ababa'),
      phone: '+251 995 880 818',
      hours: '10 AM - 8 PM',
    },
    {
      name: t('Branch - Kaliti'),
      address: t('From Kaliti to Hana Mariam Street in the opposite direction.'),
      phone: '+251 995 880 818',
      hours: '10 AM - 8 PM',
    },
  ];

  return (
    <div className="bg-slate-800 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-12" data-aos="fade-up">
          {t('Our Branches')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {locations.map((location, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <div className="flex flex-col items-center mb-4">
                <EnvironmentOutlined className="text-4xl text-blue-500 mb-2" />
                <h3 className="text-2xl font-semibold text-gray-800 mb-1">{location.name}</h3>
              </div>
              <p className="text-gray-600 mb-2 flex items-center">
                <EnvironmentOutlined className="mr-2" />
                {location.address}
              </p>
              <p className="text-gray-600 mb-2 flex items-center">
                <PhoneOutlined className="mr-2" />
                {location.phone}
              </p>
              <p className="text-gray-600 flex items-center">
                <ClockCircleOutlined className="mr-2" />
                {location.hours}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StoreLocationsSection;
