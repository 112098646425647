import React from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const ComplaintForm = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const response = await axios.post('http://localhost:3000/api/complaints', values);
      console.log('Form submission response:', response.data);
      message.success(t('Complaint submitted successfully'));
      form.resetFields(); // Clear form fields after successful submission
    } catch (error) {
      console.error('Error submitting complaint:', error);
      message.error('Failed to submit complaint. Please try again later.');
    }
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f2f5', padding: '20px' }}>
      <div style={{ maxWidth: '600px', width: '100%', background: '#fff', padding: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', borderRadius: '8px' }}>
        <div style={{ textAlign: 'center' }}>
          <Title level={2}>{t('Service Complaint Form')}</Title>
          <Paragraph>{t('Please fill in the details below to lodge your complaint')}</Paragraph>
        </div>
        <Form
          form={form}
          name="complaint_form"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          {/* Section 1: Complainant Details */}
          <div style={{ background: '#f0f2f5', padding: '20px', borderRadius: '8px', marginBottom: '20px', boxShadow: '0 0 5px rgba(0,0,0,0.1)' }}>
            <Title level={3} style={{ marginBottom: '10px' }}>{t('Complainant Details')}</Title>

            <Form.Item
              name="fullName"
              label={t('Full Name')}
              rules={[{ required: true, message: t('Please enter your full name') }]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="phone"
              label={t('Phone Number')}
              rules={[
                { required: true, message: 'Please enter your phone number' },
                { pattern: /^\d{10}$/, message: 'The input is not a valid phone number!' },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>

            <Form.Item
              name="subCity"
              label={t('Sub City')}
              rules={[{ required: true, message: 'Please enter your sub city' }]}
            >
              <Input placeholder="Sub City" />
            </Form.Item>

            <Form.Item
              name="woreda"
              label={t('Woreda')}
              rules={[{ required: true, message: 'Please enter your woreda' }]}
            >
              <Input placeholder="Woreda" />
            </Form.Item>
          </div>

          {/* Section 2: Complaint Details */}
          <div style={{ background: '#f0f2f5', padding: '20px', borderRadius: '8px', boxShadow: '0 0 5px rgba(0,0,0,0.1)' }}>
            <Title level={3} style={{ marginBottom: '10px' }}>{t('Complaint Details')}</Title>

            <Form.Item
              name="complaintPlace"
              label={t('Place where the complaint is raised')}
              rules={[{ required: true, message: 'Please enter the place where the complaint is raised' }]}
            >
              <Input placeholder="Place where the complaint is raised" />
            </Form.Item>

            <Form.Item
              name="complaintDetails"
              label={t('Detailed issue of the complaint')}
              rules={[{ required: true, message: 'Please enter the detailed issue of the complaint' }]}
            >
              <TextArea placeholder="Detailed issue of the complaint" rows={4} />
            </Form.Item>

            <Form.Item
              name="complaintWant"
              label={t('What do you want as a resolution?')}
              rules={[{ required: true, message: 'Please enter what you want as a resolution' }]}
            >
              <TextArea placeholder="What do you want as a resolution?" rows={3} />
            </Form.Item>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              {t('Submit Complaint')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ComplaintForm;
