import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const categories = [
  'Welcome Page', 'House Hold', 'Cosmetic', 'Food Stuffs', 'Stationary', 
  'Garment and Shoe', 'Electronics', 'Office Furniture', 'Beverage', 
  'Mineral Water', 'Alcohol', 'Soft Drink', 'Milk and Milk Product', 
  'Meat and Meat Product', 'Vegetables Fruit', 'Toys and Games'
];

const ProductManagement = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [filterCategory, setFilterCategory] = useState('');

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    handleFilterCategoryChange(filterCategory);
  }, [products, filterCategory]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/product');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleFilterCategoryChange = (category) => {
    setFilterCategory(category);
    if (category === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => product.category === category);
      setFilteredProducts(filtered);
    }
  };

  const handleSubmit = async (values) => {
    if (!values.name || !values.price || !values.category || !values.image) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('price', parseFloat(values.price));
    formData.append('category', values.category);
    formData.append('description', values.description);
    formData.append('image', values.image[0].originFileObj);

    try {
      await axios.post('http://localhost:3000/api/product', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Product added successfully');
      fetchProducts();
      form.resetFields();
    } catch (error) {
      console.error('Error adding product:', error);
      message.error('Failed to add product');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`http://localhost:3000/api/product/${id}`);
        message.success('Product deleted successfully');
        fetchProducts();
      } catch (error) {
        console.error('Error deleting product:', error);
        message.error('Failed to delete product');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Product',
      width: 800,
      content: (
        <div>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Price:</strong> {record.price}</p>
          <p><strong>Category:</strong> {record.category}</p>
          <p><strong>Description:</strong> {record.description}</p>
          <p><strong>Image:</strong> <img src={`http://localhost:3000/${record.imageURL}`} alt={record.name} style={{ maxWidth: '100%', height: 'auto' }} /></p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentProduct(record);
    updateForm.setFieldsValue({
      name: record.name,
      price: record.price,
      category: record.category,
      description: record.description,
      image: null,
    });
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('price', parseFloat(values.price));
    formData.append('category', values.category);
    formData.append('description', values.description);
    if (values.image && values.image.length > 0) {
      formData.append('image', values.image[0].originFileObj);
    }

    try {
      await axios.put(`http://localhost:3000/api/product/${currentProduct._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Product updated successfully');
      fetchProducts();
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating product:', error);
      message.error('Failed to update product');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      ellipsis: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Image',
      dataIndex: 'imageURL',
      key: 'imageURL',
      width: '20%',
      render: (imageURL, record) => (
        <img src={`http://localhost:3000/${imageURL}`} alt={record.name} style={{ maxWidth: '100px', height: 'auto' }} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '30%',
      render: (text, record) => (
        <div className="space-x-4">
          <Button type="primary" onClick={() => handleView(record)}>View</Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)}>Update</Button>
          <Button type="default" className='bg-red-700 p-2 text-white' onClick={() => handleDelete(record._id)}>Delete</Button>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full  p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-blue-800">Product Management</h2>
      <Form form={form} onFinish={handleSubmit} layout="vertical" className="space-y-4 mb-4">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the product name' }]}
        >
          <Input placeholder="Enter product name" />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          rules={[{ required: true, message: 'Please enter the product price' }]}
        >
          <Input placeholder="Enter product price" />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select placeholder="Select a category">
            {categories.map((cat, index) => (
              <Option key={index} value={cat}>{cat}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <TextArea placeholder="Enter product description" rows={3} />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
          rules={[{ required: true, message: 'Please upload an image' }]}
        >
          <Upload
            listType="picture"
            maxCount={1}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Product</Button>
        </Form.Item>
      </Form>

      <Select
        className="mb-4 w-full"
        placeholder="Filter by category"
        onChange={handleFilterCategoryChange}
        value={filterCategory}
      >
        <Option value="">All Categories</Option>
        {categories.map((cat, index) => (
          <Option key={index} value={cat}>{cat}</Option>
        ))}
      </Select>

      <Table
        columns={columns}
        dataSource={filteredProducts}
        pagination={{ pageSize: pageSize, current: currentPage }}
        rowKey="_id"
        onChange={(pagination) => setCurrentPage(pagination.current)}
      />

      <Modal
        title="Update Product"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <Form form={updateForm} onFinish={handleUpdateSubmit} layout="vertical" className="space-y-4">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the product name' }]}
          >
            <Input placeholder="Enter product name" />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: 'Please enter the product price' }]}
          >
            <Input placeholder="Enter product price" />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select placeholder="Select a category">
              {categories.map((cat, index) => (
                <Option key={index} value={cat}>{cat}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
          >
            <TextArea placeholder="Enter product description" rows={3} />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Upload New Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Update Product</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProductManagement;
