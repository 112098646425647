import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination, Button, Modal } from "antd";
import AOS from "aos";
import "aos/dist/aos.css";
import DefaultImage from "../assets/images/Announcemnt Image.png";
import EnjoyAnnouncements from "../Components/Announcement/EnjoyAnnouncements ";
// import AnnouncementsUI from "../Components/Announcement/AnnouncementsUI";
import HammerBanner from "../assets/images/tradeb.jpg";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from 'date-fns';

const Announcements = () => {
  const { t, i18n } = useTranslation();
  const [announcements, setAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const itemsPerPage = 12; // 3 rows x 3 columns per page

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
      duration: 2000,
    });
  }, []);

  const fetchAnnouncements = async (page) => {
    try {
      const response = await axios.get(
        `http://localhost:3000/api/announcements?page=${page}&limit=${itemsPerPage}`
      );
      setAnnouncements(response.data.announcements);
      setTotalPages(Math.ceil(response.data.total / itemsPerPage));
    } catch (error) {
      console.error("Failed to fetch announcements.", error);
    }
  };

  useEffect(() => {
    fetchAnnouncements(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const showModal = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedAnnouncement(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div>


<div className="relative shadow-2xl">
        <img src={HammerBanner} className="w-full h-48" alt="Banner" />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl lg:text-7xl font-bold">
        {t('announcements')}
        </h1>
      </div>

      <div className="w-full mx-auto p-4 bg-white shadow-lg rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
          {announcements.map((announcement, index) => (
            <div
              key={announcement._id}
              className="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg overflow-hidden mx-auto font-[sans-serif] mt-4"
              data-aos="fade-up"
              data-aos-delay={`${index * 100}`}
            >
              <div className="min-h-[256px]">
                {announcement.image && (
                  <img
                    src={`http://localhost:3000/uploads/Announcement/${announcement.image}`}
                    alt={announcement.title}
                    className="w-full h-56"
                  />
                )}
                {!announcement.image && (
                  <img
                    src={DefaultImage}
                    alt={announcement.title}
                    className="w-full h-56"
                  />
                )}
              </div>
              <div className="p-6">
                <h3 className="text-gray-800 text-xl font-bold">
                  {truncateText(announcement.title, 50)}
                </h3>
                <p className="mt-4 text-sm text-gray-500 leading-relaxed">
                  {truncateText(announcement.description, 250)}
                </p>
                <p className="text-gray-500 mt-2">
                  {formatDate(announcement.date)}
                </p>
                <Button
                  type="primary"
                  className="mt-6 px-5 py-2.5 rounded-lg text-white text-sm tracking-wider border-none outline-none bg-blue-600 hover:bg-blue-700 active:bg-blue-600"
                  onClick={() => showModal(announcement)}
                >
                  Read More
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <Pagination
            current={currentPage}
            total={totalPages * itemsPerPage}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
        {selectedAnnouncement && (
          <Modal
            title={selectedAnnouncement.title}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <Button key="close" onClick={handleCancel}>
                Close
              </Button>,
            ]}
          >
            <p className="text-gray-700">{selectedAnnouncement.description}</p>
            {selectedAnnouncement.image && (
              <img
                src={`http://localhost:3000/uploads/Announcement/${selectedAnnouncement.image}`}
                alt={selectedAnnouncement.title}
                className="w-full h-auto mt-4 rounded-lg"
              />
            )}
          </Modal>
        )}
      </div>
      <EnjoyAnnouncements />
    </div>
  );
};

export default Announcements;
