import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Upload, Button, Form, Input, message, Table, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const DirectorMessageManager = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [editId, setEditId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [visibleRows, setVisibleRows] = useState(3); // Number of rows visible at a time
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [imageFile, setImageFile] = useState(null); // State to hold the image file
  const [updateForm] = Form.useForm(); // Form instance for update modal

  useEffect(() => {
    fetchDirectorMessages();
  }, []);

  const fetchDirectorMessages = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/director/messages');
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching director messages:', error);
    }
  };

  const deleteDirectorMessage = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/api/director/messages/${id}`);
      fetchDirectorMessages();
      message.success('Director message deleted successfully');
    } catch (error) {
      console.error('Error deleting director message:', error);
      message.error('Failed to delete director message. Please try again.');
    }
  };

  const updateDirectorMessage = async (id, updatedData) => {
    try {
      const formData = new FormData();
      formData.append('title', updatedData.title);
      formData.append('description', updatedData.description);
      formData.append('name', updatedData.name);
      if (updatedData.image) {
        formData.append('image', updatedData.image);
      }
      await axios.patch(`http://localhost:3000/api/director/messages/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchDirectorMessages();
      message.success('Director message updated successfully');
    } catch (error) {
      console.error('Error updating director message:', error);
      message.error('Failed to update director message. Please try again.');
    }
  };

  const handleUpdate = (record) => {
    updateForm.resetFields(); // Reset form fields to clear any previous data
    setCurrentMessage({ ...record }); // Set the current message to edit
    setIsModalVisible(true); // Show the update modal
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields(); // Validate form fields
      const updatedData = { ...values };
      if (imageFile) {
        updatedData.image = imageFile;
      }
      updateDirectorMessage(currentMessage._id, updatedData);
      setIsModalVisible(false);
      setCurrentMessage(null); // Reset current message after submission
      setImageFile(null); // Reset image file after update
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentMessage(null); // Reset current message on modal cancel
    setImageFile(null); // Clear selected image file on cancel
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('name', values.name);
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }

    try {
      let response;
      if (editId) {
        response = await axios.patch(`http://localhost:3000/api/director/messages/${editId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setEditId(null); // Reset edit mode after update
      } else {
        response = await axios.post('http://localhost:3000/api/director/messages', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      console.log('Submitted director message data:', response.data);
      message.success(editId ? "Director message updated successfully!" : "Director message added successfully!");

      // Reset form fields and file list after successful submission
      form.resetFields();
      setFileList([]);
      fetchDirectorMessages(); // Refresh messages

    } catch (error) {
      console.error('Error submitting director message data:', error);
      message.error(editId ? "Failed to update Director message. Please try again." : "Failed to add Director message. Please try again.");
    }
  };

  const handleImageChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)); // Ensure only one file is in the list
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => truncateText(text, 20),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => truncateText(text, 40),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => viewMessage(record)} style={{ marginRight: 8 }}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => confirmDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const viewMessage = (record) => {
    Modal.info({
      title: 'View Message',
      content: (
        <div>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Description:</strong> {record.description}</p>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Image:</strong> <img src={`http://localhost:3000/uploads/DirectorMessage/${record.image}`} alt={record.title} style={{ width: '100%', maxHeight: '400px' }} /></p>
        </div>
      ),
      width: 800, // Adjust the width as needed
      onOk() {},
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this message?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteDirectorMessage(id);
      },
    });
  };

  return (
    <div className='w-full mx-auto p-4'>
      <div className="w-full mx-auto mb-8">
        <h1 className="text-2xl font-bold mb-4 text-blue-800">Add Director Message</h1>
        <Form form={form} onFinish={handleSubmit} className="space-y-4">
          <Form.Item
            name="title"
            label="Title"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input the description!' }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name of the President"
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input the name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            labelCol={{ span: 24 }}
          >
            <Upload
              listType="picture"
              beforeUpload={() => false}
              onChange={handleImageChange}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editId ? 'Update Director Message' : 'Add Director Message'}
            </Button>
          </Form.Item>
        </Form>
      </div>
      
      <div className="">
        <h2 className="text-xl font-bold mb-4 text-blue-800">Director Messages</h2>
        <Table
          columns={columns}
          dataSource={messages}
          pagination={{ pageSize: visibleRows }}
          rowKey={(record) => record._id}
        />
        <Modal
          title="Update Director Message"
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleUpdateSubmit}
          destroyOnClose={true}
        >
          <Form
            form={updateForm}
            initialValues={currentMessage} // Initialize form with currentMessage
            onFinish={handleUpdateSubmit}
            layout="vertical"
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please input the title!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please input the description!' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name of the President"
              rules={[{ required: true, message: 'Please input the name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Image">
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default DirectorMessageManager;
