import React, { useEffect } from 'react';
import { RiCoupon2Line } from 'react-icons/ri'; // Importing coupon icon from react-icons
import { FaRegLightbulb } from 'react-icons/fa'; // Importing lightbulb icon from react-icons
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useTranslation } from "react-i18next";

const CoolAddition = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animation
      once: true, // Only animate once
    });
  }, []);
  const { t } = useTranslation();
  return (
    <section className="bg-white dark:bg-gray-900 py-12">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Column */}
          <div className="text-center" data-aos="fade-up">
            <RiCoupon2Line className="text-6xl text-blue-500 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">{t('Special Offers & Coupons')}</h3>
            <p className="text-gray-600 dark:text-gray-400">{t('Discover exclusive discounts and special offers available at Friendship Hypermarket. Save big on your favorite products!')}</p>
          </div>
          {/* Right Column */}
          <div className="text-center" data-aos="fade-up" data-aos-delay="200">
            <FaRegLightbulb className="text-6xl text-yellow-500 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">{t('Innovative Ideas')}</h3>
            <p className="text-gray-600 dark:text-gray-400">{t("Stay updated with the latest innovations and ideas from Friendship Hypermarket. We're always striving to bring you the best!")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoolAddition;
