import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ShoppingCartOutlined, TagsOutlined, SafetyCertificateOutlined, SmileOutlined, TeamOutlined, GiftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ServicesPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const services = [
    {
      icon: <ShoppingCartOutlined className="text-4xl text-blue-500" />,
      title: t('Wide Range of Products'),
      description: t('Explore a vast selection of products from groceries to electronics, all under one roof.'),
    },
    {
      icon: <TagsOutlined className="text-4xl text-blue-500" />,
      title: t('Best Prices'),
      description: t('Enjoy competitive pricing and fantastic deals on all your favorite items.'),
    },
    {
      icon: <SafetyCertificateOutlined className="text-4xl text-blue-500" />,
      title: t('Quality Assurance'),
      description: t('We ensure the highest quality standards for all our products.'),
    },
    {
      icon: <SmileOutlined className="text-4xl text-blue-500" />,
      title: t('Customer Satisfaction'),
      description: t('Our friendly staff is here to ensure you have a pleasant shopping experience.'),
    },
    {
      icon: <TeamOutlined className="text-4xl text-blue-500" />,
      title: t('Professional Staff'),
      description: t('Our trained professionals are here to assist you with all your needs.'),
    },
    {
      icon: <GiftOutlined className="text-4xl text-blue-500" />,
      title: t('Special Promotions'),
      description: t('Take advantage of our exclusive promotions and discounts.'),
    },
  ];

  return (
    <div>
      <div className="bg-slate-800 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-white mb-12" data-aos="fade-up">
            {t('Our Services')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition-shadow duration-300"
                data-aos="fade-up"
                data-aos-delay={index * 200}
              >
                <div className="mb-4">
                  {service.icon}
                </div>
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesPage;
