import React from 'react'
import ServicesComp from "../Components/Services/Services"
import { useTranslation } from 'react-i18next';
import Features from '../Components/Features';
import Offers from "../Components/Services/Offers"
import Location from "../Components/Services/Locations"
import AnnouncemetFooter from "../Components/AnnouncemetFooter"

function Services() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div>
      <ServicesComp/>
      </div>
      <div>
        <Offers/>
      </div>
      <div>
      {/* <Location/> */}
      </div>
      <div >
        <div className=''>
        <AnnouncemetFooter/>
        </div>
      <Features/>
      </div>
     
    </div>
  )
}

export default Services