

import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import IMG01 from "../assets/Friendship/logofriend.png";
import IMG02 from "../assets/Friendship/11.jpg";
import IMG03 from "../assets/Friendship/fruit.jpg";
import IMG04 from "../assets/Friendship/material.jpg";
import { useTranslation } from "react-i18next";
const HomePage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <div className="relative h-[84vh]">
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 to-gray-800 opacity-75"></div>

      {/* Carousel */}
      <Carousel
        showThumbs={false}
        infiniteLoop
        autoPlay
        interval={5000}
        transitionTime={1000}
        className="w-full h-[80vh]"
      >
        <div className="relative h-full">
          <img src={IMG01} alt="Slider 1" className="w-full h-full object-cover" />
        </div>
        <div className="relative h-full">
          <img src={IMG02} alt="Slider 2" className="w-full h-full object-cover" />
        </div>
        <div className="relative h-full">
          <img src={IMG03} alt="Slider 3" className="w-full h-full object-cover" />
        </div>
      </Carousel>

      {/* Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white z-10">
        {/* Additional Background Overlay for Text */}
        <div className="absolute inset-0 bg-black bg-opacity-50 z-0"></div>
        <div className="relative z-10">
          <h1 
            className="text-3xl md:text-5xl lg:text-6xl font-bold mb-4 text-yellow-500"
            data-aos="fade-down"
          >
           {t('Welcome to Friendship Hypermarket')}
          </h1>
          <p 
           className="text-lg md:text-xl lg:text-2xl max-w-2xl mb-8 mx-4 text-center mx-auto"
            data-aos="fade-up"
          >
            {t('Your one-stop destination for all your shopping needs. Discover a wide range of products, unbeatable prices, and exceptional customer service.')}
          </p>
          <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4" data-aos="fade-up">
            <button className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition duration-300">
             {t('Shop Now')}
            </button>
            <button className="bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-700 transition duration-300">
              {t('Contact Us')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

