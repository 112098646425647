import React from 'react';
import { useTranslation } from "react-i18next";

const CourtOperatingHours = () => {
  const { t } = useTranslation();
  
  const hours = [
    { day: t('Monday'), time: '07:30 AM - 10:00 PM' },
    { day: t('Tuesday'), time: '07:30 AM - 10:00 PM' },
    { day: t('Wednesday'), time: '07:30 AM - 10:00 PM' },
    { day: t('Thursday'), time: '07:30 AM - 10:00 PM' },
    { day: t('Friday'), time: '07:30 AM - 10:00 PM' },
    { day: t('Saturday'), time: '07:30 AM - 10:00 PM' },
    { day: t('Sunday'), time: '07:30 AM - 10:00 PM' },
  ];

  return (
    <div className="bg-white py-12 px-6 sm:px-12 lg:px-24">
      <h2 className="text-4xl lg:text-4xl font-bold text-center mb-12 text-blue-800">
        {t('Operating Hours')}
      </h2>
      <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3">
        {hours.map((hour, index) => (
          <div key={index} data-aos="fade-right" data-aos-duration="2000" className="bg-gray-50 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-blue-700">{hour.day}</h3>
            <p className="text-lg text-gray-700">
              {hour.time}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourtOperatingHours;
