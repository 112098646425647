import { Button, Col, Row, Typography, Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HammerBanner from "../assets/Friendship/Bantwo.png";
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const itemsPerPage = 16; // 4 columns * 4 rows = 16 items per page

const ProductNavigation = () => {
  const { t, i18n } = useTranslation();
  const [activeCategory, setActiveCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Maintain a static mapping of categories
  const categoryMappings = {
    'House Hold': 'House Hold',
    'Cosmetic': 'Cosmetic',
    'Food Stuffs': 'Food Stuffs',
    'Stationary': 'Stationary',
    'Garment and Shoe': 'Garment and Shoe',
    'Electronics': 'Electronics',
    'Office Furniture': 'Office Furniture',
    'Beverage': 'Beverage',
    'Mineral Water': 'Mineral Water',
    'Alcohol': 'Alcohol',
    'Soft Drink': 'Soft Drink',
    'Milk and Milk Product': 'Milk and Milk Product',
    'Meat and Meat Product': 'Meat and Meat Product',
    'Vegetables Fruit': 'Vegetables Fruit',
    'Toys and Games': 'Toys and Games'
  };

  // Translate category names
  const categories = Object.keys(categoryMappings).map(category => t(categoryMappings[category]));

  useEffect(() => {
    if (activeCategory) {
      fetchProducts(activeCategory, 1);
    }
  }, [activeCategory]);

  useEffect(() => {
    if (i18n.language && activeCategory) {
      fetchProducts(activeCategory, currentPage);
    }
  }, [i18n.language]);

  const fetchProducts = (category, page) => {
    axios.get(`http://localhost:3000/api/product/${category}?page=${page}`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setCurrentPage(1); // Reset page to 1 when category changes
  };

  const addToCart = (productId) => {
    console.log(`Added product ${productId} to cart`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchProducts(activeCategory, page);
  };

  const renderPagination = () => {
    const totalItems = products.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    if (totalPages > 1) {
      return (
        <div className="text-center my-4">
          <Button.Group>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
              <Button key={page} type={currentPage === page ? 'primary' : 'default'} onClick={() => handlePageChange(page)}>
                {page}
              </Button>
            ))}
          </Button.Group>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
    {/* <div className="relative shadow-2xl">
        <img src={HammerBanner} className="w-full h-48" alt="Banner" />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl lg:text-7xl font-bold">
            {t('Market Price')}
        </h1>
      </div> */}


      <div className="relative shadow-2xl ">
        <img
          src={HammerBanner}
          className="w-full h-48 "
          alt="Banner"
        />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl lg:text-7xl font-bold">
          {t('Market Price')}
        </h1>
      </div> 
      <div className="container mx-auto">
        {/* Navigation Bar */}
        <nav className="bg-gray-800 p-4 rounded shadow-lg mb-8">
          <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4 text-white">
            {categories.map((category, index) => (
              <li key={index}>
                <Button
                  type='default'
                  className={`w-full text-center py-2 text-white font-semibold rounded-lg transition duration-300 ease-in-out ${activeCategory === category ? 'bg-yellow-500' : 'bg-blue-800 hover:bg-blue-600'}`}
                  onClick={() => handleCategoryClick(Object.keys(categoryMappings).find(key => t(key) === category))}
                >
                  {category}
                </Button>
              </li>
            ))}
          </ul>
        </nav>

        {/* Product Grid */}
        <div className="p-4 bg-gray-100 rounded-lg shadow-inner">
          {activeCategory && products.length > 0 ? (
            <>
              <Row gutter={[16, 16]}>
                {products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((product, index) => (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    <div className="bg-white p-4 shadow-lg rounded-lg text-center flex flex-col justify-between h-full transform transition duration-300 hover:scale-105">
                      <img alt={product.name} src={`http://localhost:3000/${product.image}`} className="mx-auto mb-4 rounded-lg" style={{ maxHeight: '200px', objectFit: 'cover' }} />
                      <div>
                        <Title level={5} ellipsis={{ tooltip: product.name }} className="mb-2 "><p className='text-xl'>{product.name}</p></Title>
                        <div>
                          <p className="mb-2 bg-gray-700 p-2 text-white text-xl rounded-2xl hover:bg-slate-600">{product.price.toFixed(2)} Birr</p>
                        </div>
                      </div>
                      {/* <Button type="primary" onClick={() => addToCart(product._id)}>Add to Cart</Button> */}
                    </div>
                  </Col>
                ))}
              </Row>
              {renderPagination()}
            </>
          ) : (
            <div className="text-center mt-8">
              <Empty description={<Title level={4}>Welcome to Friendship Hypermarket!</Title>} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductNavigation;
