import React, { useEffect } from "react";
import AbbaGada from "../assets/images/BD.jpg";
import HadaSinqe from "../assets/images/WeStandFor.png";
import Gathering from "../assets/images/MarketingCompliance.png";
import WH from "../assets/images/ConsumerProtection.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function WhatWeStandFor() {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <div>
      {/* First Section */}
      <div className="flex justify-center items-center p-5 bg-gray-100">
        <div className="flex flex-col w-full max-w-6xl sm:flex-row">
          {/* Text Section */}
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="w-full sm:w-1/2 p-5"
          >
            <div>
              <h2 className="text-3xl font-bold text-center mb-8">
                {t('What We Stand For')}
              </h2>
            </div>
            <div>
              <p className="text-2xl">
               {t('What We Stand For Description')}
              </p>
            </div>
          </div>

          {/* Divider */}
          <div
            data-aos="zoom-in"
            data-aos-duration="2000"
            className="w-full sm:w-px bg-red-500 my-5 sm:my-0 sm:mx-5"
          ></div>

          {/* Image Section */}
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="w-full  sm:w-1/2 flex items-center justify-center p-5 "
          >
            <img
              src={HadaSinqe}
              alt="Hada Sinqe"
              className="w-80 sm:w-96 h-80 object-fit"
            />
          </div>
        </div>
      </div>

      {/* Second Section */}
      <div className="flex justify-center items-center p-5 bg-gray-100">
        <div className="flex flex-col w-full max-w-6xl sm:flex-row">
          {/* Image Section */}
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="w-full sm:w-1/2 flex items-center justify-center p-5"
          >
            <img
              src={AbbaGada}
              alt="Abba Gada"
              className="w-80 sm:w-96 h-80 object-cover"
            />
          </div>
          {/* Divider */}
          <div
            data-aos="zoom-in"
            data-aos-duration="2000"
            className="w-full sm:w-px bg-red-500 my-5 sm:my-0 sm:mx-5"
          ></div>
          {/* Text Section */}
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="w-full sm:w-1/2 p-5"
          >
            <h2 className="text-3xl font-bold text-center mb-5">
              {t('Business Development and Support')}
            </h2>
            <p className="text-2xl">
              {t('Business Development and Support Description')}
            </p>
          </div>
        </div>
      </div>

      {/* Third Section */}
      <div className="flex justify-center items-center p-5 bg-gray-100">
        <div className="flex flex-col w-full max-w-6xl sm:flex-row">
          {/* Text Section */}
          <div
            data-aos="fade-right"
            data-aos-duration="3000"
            className="w-full sm:w-1/2 p-5"
          >
            <h2 className="text-3xl font-bold text-center mb-8">
             {t('Market Regulation and Compliance')}
            </h2>
            <p className="text-2xl">
              {t('Market Regulation and Compliance Description')}
            </p>
          </div>

          {/* Divider */}
          <div
            data-aos="zoom-in"
            data-aos-duration="2000"
            className="w-full sm:w-px bg-red-500 my-5 sm:my-0 sm:mx-5"
          ></div>

          {/* Image Section */}
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="w-full sm:w-1/2 flex items-center justify-center p-5"
          >
            <img
              src={Gathering}
              alt="Gathering"
              className="w-80 sm:w-96 h-80 object-cover"
            />
          </div>
        </div>
      </div>

      {/* Fourth Section */}
      <div className="flex justify-center items-center p-5 bg-gray-100">
        <div className="flex flex-col w-full max-w-6xl sm:flex-row">
          {/* Image Section */}
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="w-full sm:w-1/2 flex items-center justify-center p-5"
          >
            <img src={WH} alt="WH" className="w-80 sm:w-96 h-80 object-cover" />
          </div>
          {/* Divider */}
          <div
            data-aos="zoom-in"
            data-aos-duration="2000"
            className="w-full sm:w-px bg-red-500 my-5 sm:my-0 sm:mx-5"
          ></div>
          {/* Text Section */}
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="w-full sm:w-1/2 p-5"
          >
            <h2 className="text-3xl font-bold text-center mb-5">
              {t('Consumer Protection and Fair Trade')}
            </h2>
            <p className="text-2xl">
             {t('Consumer Protection and Fair Trade Description')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeStandFor;
