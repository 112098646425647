import React from 'react';
import { Spin } from 'antd';
import 'antd/dist/reset.css'; // Ant Design CSS Reset
import { LoadingOutlined } from '@ant-design/icons';

const Loader = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <Spin indicator={antIcon} />
        <p className="mt-4 text-lg font-semibold text-gray-700">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;
