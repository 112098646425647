import React from "react";
import { Button } from "antd";
import Gallery from "../Components/Gallery";
import { useTranslation } from "react-i18next";
import Loader from "../Components/Loader/Loader";
function GallaryApp() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Gallery />
    </div>
  );
}

export default GallaryApp;
