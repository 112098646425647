import React from 'react'
import Users from '../Components/Admin/Users'
import NavbarA from '../Components/Admin/NavbarA'
import FooterA from '../Components/Admin/AddNews'
function Admin() {
  return (
    <div>
        <NavbarA/>
    </div>
  )
}

export default Admin