import React from "react";
import CarouselEffect from "../Components/CarouselEffect";
import CFooter from "../Components/CFooter";
import StandFor from "../Components/StandFor"
import Banner from "../Components/Banner";
import Contact from "../Components/Contact";
import DirectorMessage from "../Components/DirectorMessage";
import NewsHero from "../Components/News/NewsHero"
import { useTranslation } from 'react-i18next';
import HomeContactus from "../Components/HomeContactus"

import OurPartners from "../Components/OurPartners"
import Services from "../Components/Services/Services"
import Offers from "../Components/Services/Offers"
import Location from "../Components/Services/Locations"
import WelcomeSection from "../Components/WelcomeSection";
import SocialMediaLinks from "../Components/SocialMediaLink";
import ContactUs from "../Components/HomeContactus"
import FriendshipGroupInfo from "../Components/FrienshiopGropInfo";
import GroupInfo from "../Components/GroupInfo";
import Testimonials from "../Components/Testimonials"
import AnnouncemetFooter from "../Components/AnnouncemetFooter"
import ServicesTwo from "../Components/ServicesTwo"
function Home() {
  const { t, i18n } = useTranslation();

  return (
    <div className="relative">
     <CarouselEffect/>  
     {/* <CFooter/>
     <StandFor/> */}
     {/* <WelcomeSection/> */}
     <FriendshipGroupInfo/>
     <ServicesTwo/>
     <Banner/>
     {/* <DirectorMessage/> */}
     <Offers/>
     <Location/>
     <SocialMediaLinks/>
     <GroupInfo/>
     {/* <NewsHero/> */}
    <AnnouncemetFooter/>
     <ContactUs/>
     {/* <OurPartners/> */}
     <div>
      <Testimonials/>
     </div>
    </div>
  );
}

export default Home;