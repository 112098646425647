import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import News from '../../Pages/News';
import NewsHero from "./NewsHero"
const NewsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Default values if location.state is null
  const { imageURL, title, description } = location.state || {
    imageURL: '',
    title: 'No Title Available',
    description: 'No Description Available'
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
      <div className='p-4'>
        <button onClick={handleBack} className="text-white bg-blue-900 p-2 rounded-lg">
          Back
        </button>
      </div>
        <div className="border rounded-md shadow-md hover:shadow-lg transition duration-300">
          <img
            src={`http://localhost:3000/${imageURL}`}
            alt={title}
            className="w-full h-80 object-cover rounded-t-md"
          />
          <div className="p-6">
            <h3 className="text-3xl font-semibold mb-4">{title}</h3>
            <p className="text-gray-700 text-lg leading-relaxed mb-4">
              {description}
            </p>
          </div>
        </div>
        
      </div>
      <NewsHero/>
    </div>
  );
};

export default NewsDetail;
