import React, { useEffect } from "react";
import { Button } from "antd";
import bannerImage from "../assets/Friendship/Backgrond.jpg"; // Import the image
import "aos/dist/aos.css";
import aos from "aos";
import { useTranslation } from "react-i18next";

const FriendshipGroupInfo = () => {
  useEffect(() => {
    aos.init({ duration: 1000 }); // Initialize AOS with options if needed
  }, []);
  const { t } = useTranslation();
  return (
    <div
      className="relative bg-cover bg-center h-screen flex items-center justify-center"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="relative z-10 text-center text-white p-10 sm:p-20">
        <h1
          className="text-5xl sm:text-6xl font-extrabold mb-8 text-yellow-500"
          data-aos="fade-down"
        >
          {t("Discover the Friendship Hypermarket Difference")}
        </h1>
        <p
          className="text-xl sm:text-2xl lg:text-3xl mb-10 text-blue-300"
          data-aos="fade-up"
        >
          {t(
            "At Friendship Hypermarket, we offer more than just products; we provide an exceptional shopping experience. Our commitment to quality and customer service builds lasting relationships with our community. We aim to positively impact the local economy and enhance your quality of life with top-notch goods and unparalleled service. Visit Friendship Hypermarket today and experience the difference—your satisfaction is our top priority."
          )}
        </p>
        <div className="flex justify-center space-x-6" data-aos="zoom-in">
          <Button type="primary" size="large" className="px-8 py-3">
            {t('Shop Now')}
          </Button>
          <Button size="large" className="px-8 py-3">
            {t('contact us')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FriendshipGroupInfo;
