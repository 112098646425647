import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import ErrorPage from "./Pages/ErrorPage";
import MainLayout from "./Pages/MainLayout";
import Contact from "./Pages/Contact";
import News from "./Pages/News";
import Gallary from "./Pages/Gallary";
import ShowNews from "./Pages/ShowNews";
import Admin from "./Pages/Admin";
import Users from "./Components/Admin/Users";
import NewsDetail from "./Components/News/NewsDetail";
import Officials from "./Pages/Officials";
import AdminWelcomePage from "./Components/Admin/AdminWelcomePage";
import Announcement from "./Pages/Announcement";
import Complaint from "./Pages/Complaint";
import MarketPrice from "./Pages/MarketPrice";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "services",
          element: <Services />,
        },
        {
          path: "news",
          element: <News />,
        },
        {
          path: "announcements",
          element: <Announcement/>,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "gallery",
          element: <Gallary />,
        },
        {
          path: "mainhome",
          element: <AdminWelcomePage/>,
        },
        {
          path: "officials",
          element: <Officials/>,
        },
        {
          path: "/newsdetail/:id",
          element: <NewsDetail/>,
        },
        {
          path: "complaint",
          element: <Complaint/>,
        },
        {
          path: "marketprice",
          element: <MarketPrice/>,
        }
      ],
    },
    {
      path: "admin",
      element: <Admin />,
    },
    {
      path: "feedback",
      element: <Users />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
