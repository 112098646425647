import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faFacebookF, 
  faTwitter, 
  faInstagram,
  faTiktok,
  faTelegram
} from "@fortawesome/free-brands-svg-icons";
import "aos/dist/aos.css";
import aos from "aos";

const SocialMediaLinks = () => {
  useEffect(() => {
    aos.init({ duration: 1000 }); // Initialize AOS with options if needed
  }, []);

  return (
    <div className="flex justify-center bg-blue-800 p-14">
      {/* Facebook */}
      <a href="#" className="text-white hover:text-gray-100 mx-3 flex items-center" data-aos="fade-up">
        <FontAwesomeIcon icon={faFacebookF} size="2x" />
        <span className="ml-2">Friendship Hypermarket</span>
      </a>
      
      {/* Twitter */}
      <a href="#" className="text-white hover:text-gray-100 mx-3 flex items-center" data-aos="fade-up" data-aos-delay="100">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
        <span className="ml-2">Friendship Hypermarket</span>
      </a>
      
      {/* Instagram */}
      <a href="#" className="text-white hover:text-gray-100 mx-3 flex items-center" data-aos="fade-up" data-aos-delay="200">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
        <span className="ml-2">Friendship Hypermarket</span>
      </a>
      
      {/* TikTok */}
      <a href="#" className="text-white hover:text-gray-100 mx-3 flex items-center" data-aos="fade-up" data-aos-delay="300">
        <FontAwesomeIcon icon={faTiktok} size="2x" />
        <span className="ml-2">Friendship Hypermarket</span>
      </a>
      
      {/* Telegram */}
      <a href="#" className="text-white hover:text-gray-100 mx-3 flex items-center" data-aos="fade-up" data-aos-delay="400">
        <FontAwesomeIcon icon={faTelegram} size="2x" />
        <span className="ml-2">Friendship Hypermarket</span>
      </a>
      
      {/* Add more social media icons as needed */}
    </div>
  );
}

export default SocialMediaLinks;
